import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './marsBaseQuery.ts';

export const formatDate = (dateStr: string | null): string => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

interface StatsData {
    id: number;
    green: number;
    orange: number;
    red: number;
    yellow: number;
    decret: number;
    rnd: number;
    pet: number;
    podrab: number;
    svo: number;
    projectchz: number;
    allnumshift: number;
    staz1: number;
    staz2: number;
    staz3: number;
    staz4: number;
    staz5: number;
    staz6: number;
    age1m: number;
    age1f: number;
    age2m: number;
    age2f: number;
    age3m: number;
    age3f: number;
    age4m: number;
    age4f: number;
    age5m: number;
    age5f: number;
    allagem: number;
    allagef: number;
    datesave: string;
}

export const statsMonthMarsSlice = createApi({
    reducerPath: 'statsMonthMars',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['StatsMonthMars'],
    endpoints: (builder) => ({
        getStatsMonthMars: builder.query<StatsData[], void>({
        query: () => ({
            url: `/stats_mars/all`,
            method: 'GET',
        }),
        transformResponse: (response: StatsData[]) =>
            response.map((stats) => ({
                ...stats,
                datesave: formatDate(stats.datesave)
        })),
        providesTags: ['StatsMonthMars'],
        }),
    }),
});

export const { useGetStatsMonthMarsQuery } = statsMonthMarsSlice;