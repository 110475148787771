import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./marsBaseQuery.ts";


interface DayRecord {
  type: string;
  numhours: number;
  staz?: boolean;
}

interface Employee {
  id: number;
  name: string;
  shift: string;
  post: number;
  days: { [date: string]: DayRecord[] };
}

export interface TabelData {
  shiftName: string;
  employees: Employee[];
}

interface Area {
  id: number;
  namearea: string;
}

interface TabelByEmployee {
  area: Area;
  date: string;
  id: number;
  type: "day" | "night";
  staz: boolean;
  numhours: number;
}

export const tabelMarsSlice = createApi({
  reducerPath: "tabelMars",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["TabelMars"],
  endpoints: (builder) => ({
    // Получение табеля по диапазону дат
    getTabelByDate: builder.query<
      TabelData[],
      { startDate: string; endDate: string }
    >
    ({
      query: ({ startDate, endDate }) => ({
        url: `/tabel_day`,
        method: "GET",
        params: { datestart: startDate, dateend: endDate }, // Используем params
      }),
      transformResponse: (response: TabelData[]) => {
        console.log("Raw response:", response);
      
        return response;
      },
      providesTags: ["TabelMars"],
    }),

    getExample: builder.query<string, void>({
      // Исправлен тип string
      query: () => ({
        url: `/tabel_day/example`,
        method: "GET",
      }),
      providesTags: ["TabelMars"],
    }),

    getTabelByEmployee: builder.query<
      TabelByEmployee[],
      { employeeId: number }
    >({
      query: ({ employeeId }) => ({
        url: `/tabel_day/day_by_employee/${employeeId}`,
        method: "GET",
      }),
      transformResponse: (response: TabelByEmployee[]) =>
        response?.map((data) => ({
          ...data,
          date: data.date,
        })) || [],
      providesTags: ["TabelMars"],
    }),
  }),
});

export const {
  useGetTabelByDateQuery,
  useGetExampleQuery,
  useGetTabelByEmployeeQuery,
} = tabelMarsSlice;
