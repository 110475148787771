import React, { useEffect, useState } from "react";
import Notification from "../Components/notification.tsx";
import { format } from "date-fns";
import Select, { SingleValue } from "react-select";
import { TextField, CircularProgress } from "@mui/material";
import { useGetTabelByEmployeeQuery } from "../Global/tabelMarsSlice.ts";
import dayjs, { Dayjs } from "dayjs";

interface SmenOption {
  value: number;
  label: string;
}

const ModalAddPremDepTabel = ({
  isOpen,
  onClose,
  selectedEmployeeId,
  selectedEmployeeFio,
  refetchData,
}) => {
  const { data, isLoading, error, refetch } = useGetTabelByEmployeeQuery(
    { employeeId: selectedEmployeeId },
    { skip: !selectedEmployeeId }
  );

  const [dateday, setDateday] = useState<Dayjs | null>(null);
  const [selectedSmen, setSelectedSmen] =
    useState<SingleValue<SmenOption>>(null);
  const [summ, setSumm] = useState("");
  const [comm, setComm] = useState("");

  const typeOptions = [
    { value: true, label: "Премирование" },
    { value: false, label: "Депремирование" },
  ];

  const [selectedType, setSelectedType] = useState<{
    value: boolean;
    label: string;
  } | null>(typeOptions[0]);

  const formatDate = (dateStr: string) => {
    const parsedDate = new Date(dateStr);
    return format(parsedDate, "dd.MM.yyyy");
  };

  const smenOptions: SmenOption[] =
    data?.map((smen) => ({
      value: smen.id,
      label: `${formatDate(smen.date)} - ${
        smen.type === "night" ? "Ночь" : "День"
      } - ${smen.area.namearea}`,
    })) || [];

  useEffect(() => {
    if (!selectedSmen || !data) return;

    const selectedData = data.find((item) => item.id === selectedSmen.value);
    if (!selectedData) return;

    setDateday(dayjs(selectedData.date));
  }, [data]);

  const isDateInRange = (date) => {
    const today = dayjs().startOf("day");
    const twoDaysAgo = today.subtract(2, "day");
    const oneDayAhead = today.add(1, "day");
    const inputDate = dayjs(date).startOf("day");
    return inputDate.isAfter(twoDaysAgo) && inputDate.isBefore(oneDayAhead);
  };

  const confirmAdd = async () => {
    if (!selectedSmen || !selectedType || !summ || !comm) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Необходимо указать все данные",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }

    if (
      localStorage.getItem("role") === "Бригадир" &&
      !isDateInRange(dateday)
    ) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Эту смену может редактировать только менеджер",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }

    try {
      setNotification({
        status: "loading",
        title: "Обновление данных",
        subtitle: "Пожалуйста, подождите...",
        subtitle2: <div></div>,
        isVisible: true,
      });

      const formattedDateday = dateday ? dateday.toISOString() : "";

      // const TabelDayData = {
      //   id: smenid,
      //   areaid: selectedArea?.value,
      //   date: formattedDateday,
      //   type: typesmen,
      //   numhours: numhour,
      //   staz: staz,
      //   fio_akk: localStorage.getItem("fio_mars"),
      //   role_akk: localStorage.getItem("role"),
      // };

      // await axiosInstance.post("/tabel_day/day_edit", TabelDayData);

      // await refetchData();

      // setSmenid(Number(null));
      // setDateday(null);
      // setTypesmen("");
      // setNumhour(String(null));
      // setStaz(false);
      // setSelectedArea(null);
      // setSelectedSmen(null);

      hideNotification();

      onClose();
    } catch (error) {
      const errorMessage = error.response?.data || "Ошибка при редактировании";
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: errorMessage,
        subtitle2: <div></div>,
        isVisible: true,
      });
    }
  };

  const [notification, setNotification] = useState({
    status: "",
    title: "",
    subtitle: "",
    subtitle2: <div></div>,
    isVisible: false,
  });

  const hideNotification = () => {
    setNotification({
      ...notification,
      isVisible: false,
    });
  };

  if (!isOpen) return null;

  return (
    <div className="modal_tabel_mars">
      <div className="modal_tabel_mars_cont">
        <h3 className="title_employee_efes">Премирование/депремирование</h3>
        <label
          style={{
            marginBottom: "15px",
          }}
        >
          ФИО: {selectedEmployeeFio}
        </label>

        <Select
          id="mentor-dropdown"
          value={selectedSmen}
          options={smenOptions}
          onChange={(smenOptions) => setSelectedSmen(smenOptions)}
          placeholder="Выберите смену"
          styles={{
            menu: (base) => ({
              ...base,
              zIndex: 10,
            }),
          }}
        />

        <Select
          value={selectedType}
          onChange={(option) => setSelectedType(option)}
          options={typeOptions}
          styles={{
            control: (base) => ({
              ...base,
              marginTop: "10px",
              height: "40px",
            }),
            menu: (base) => ({
              ...base,
              zIndex: 10,
            }),
          }}
        />

        <TextField
          label="Сумма"
          type="text"
          value={summ}
          onChange={(e) => setSumm(e.target.value)}
          size="small"
          sx={{
            marginBottom: "13px",
            marginTop: "13px",
            width: "100%",
            "& .MuiInputBase-input": {
              fontSize: 15,
            },
            "& .MuiFormLabel-root": {
              fontSize: 15,
            },
          }}
        />

        <TextField
          label="Комментарий"
          multiline
          rows={4}
          value={comm}
          onChange={(e) => setComm(e.target.value)}
          sx={{
            "& .MuiInputBase-input": {
              fontSize: 15,
            },
            "& .MuiFormLabel-root": {
              fontSize: 15,
            },
          }}
        />

        <div className="modal_act_fir">
          {/* <button className="btn_modal_tabel_mars" onClick={confirmEdit}>
            Сохранить
          </button> */}
          <button className="btn_modal_tabel_mars" onClick={onClose}>
            Закрыть
          </button>
        </div>
      </div>

      {notification.isVisible && (
        <Notification
          status={notification.status}
          title={notification.title}
          subtitle={notification.subtitle}
          onClose={hideNotification}
          isVisible={notification.isVisible}
          subtitle2={notification.subtitle2}
        />
      )}
    </div>
  );
};

export default ModalAddPremDepTabel;
