import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './marsBaseQuery.ts';

interface VacationData {
  [shift: string]: {
    [employeeName: string]: {
      id: number;       // Добавляем ID сотрудника
      dates: string[];  // Оставляем массив строк для дат
      shift: string;
    };
  };
}

  
  
export const vacationsMarsSlice = createApi({
    reducerPath: 'vacationsMars',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['VacationsMars'],
    endpoints: (builder) => ({
        getAllVacationsMars: builder.query<VacationData, number>({
        query: (year) => ({
            url: `/vacations/vacations_all_table/${year}`,
            method: 'GET',
        }),
        providesTags: ['VacationsMars'],
        }),
    }),
});

export const { useGetAllVacationsMarsQuery } = vacationsMarsSlice;