import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { TextField, CircularProgress } from "@mui/material";
import {
  useGetExampleQuery,
  useGetTabelByDateQuery,
} from "../Global/tabelMarsSlice.ts";
import { skipToken } from "@reduxjs/toolkit/query";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs, { Dayjs } from "dayjs";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import HomeIcon from "@mui/icons-material/Home";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../Styles/tabel.scss";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import Notification from "../Components/notification.tsx";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Select, { SingleValue } from "react-select";
import axiosInstance from "../../axiosConfig.js";
import { Checkbox, FormControlLabel } from "@mui/material";
import ModalAddAllTabel from "../Components/modal_add_all_tabel.tsx";
import CreateIcon from "@mui/icons-material/Create";
import ModalEditTabel from "../Components/modal_edit_tabel.tsx";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ModalAddPremDepTabel from "../Components/modal_add_prem_dep_tabel.tsx";

interface AreaOption {
  value: number;
  label: string;
}

interface Area {
  id: number;
  namearea: string;
}

interface ShiftOption {
  value: number | "all";
  label: string;
}

interface Shift {
  id: number;
  nameshift: string;
}

const Tabel = () => {
  const [isModalAddAllTabelOpen, setIsModalAddAllTabelOpen] = useState(false);
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs().startOf("month")
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs().endOf("month"));
  const [triggerQuery, setTriggerQuery] = useState(false);
  const [confirmedStartDate, setConfirmedStartDate] = useState<string | null>(
    null
  );

  const formatDataKeys = (
    data: Array<{ shiftName: string; employees: any[] }>
  ) => {
    const formattedData: Record<string, any> = {};

    // Шаг 1: Пройдем по каждой смене
    data.forEach((shiftItem) => {
      const { shiftName, employees } = shiftItem;

      if (!formattedData[shiftName]) {
        formattedData[shiftName] = [];
      }

      // Добавляем сотрудников в соответствующую смену
      employees.forEach((employee) => {
        const { id, fio, shift, post, days } = employee;

        // Добавляем сотрудника в массив для этой смены
        formattedData[shiftName].push({
          employeeInfo: { id, fio, shift, post },
          days,
        });
      });
    });

    // Шаг 2: Сортировка сотрудников по post == 1 и по алфавиту
    for (const shiftName in formattedData) {
      // Получаем массив сотрудников для этой смены
      const shiftEmployees = formattedData[shiftName];

      // Сортируем сначала по post (пост 1 идет первым), потом по fio
      formattedData[shiftName] = shiftEmployees.sort((a, b) => {
        // Сортируем по post == 1 сначала
        if (a.employeeInfo.post === 1 && b.employeeInfo.post !== 1) return -1;
        if (b.employeeInfo.post === 1 && a.employeeInfo.post !== 1) return 1;

        // Если post одинаковые, сортируем по fio (по алфавиту)
        return a.employeeInfo.fio.localeCompare(b.employeeInfo.fio);
      });
    }
    return formattedData;
  };

  const [confirmedEndDate, setConfirmedEndDate] = useState<string | null>(null);

  const { data, isLoading, error, refetch } = useGetTabelByDateQuery(
    triggerQuery && confirmedStartDate && confirmedEndDate
      ? { startDate: confirmedStartDate, endDate: confirmedEndDate }
      : skipToken,
    { skip: !triggerQuery }
  );

  const formattedData = useMemo(() => formatDataKeys(data || []), [data]);

  const [notification, setNotification] = useState({
    status: "",
    title: "",
    subtitle: "",
    subtitle2: <div></div>,
    isVisible: false,
  });

  const hideNotification = () => {
    setNotification({
      ...notification,
      isVisible: false,
    });
  };

  const formatDate = (dateStr: string) => {
    const parsedDate = new Date(dateStr);
    return format(parsedDate, "dd.MM.yyyy");
  };

  const shiftColors: { [key: string]: string } = {
    Красная: "#D60000",
    Оранжевая: "#FF8C00",
    Желтая: "#F9D400",
    Зеленая: "#00840D",
    Подработчик: "#6d21b5",
    "Проект ЧЗ": "#1096e3",
    "Отдел rnd": "#e310a7",
    Петцентр: "#10e3a4",
    Декрет: "#E2E8F1",
    СВО: "#E2E8F1",
  };

  useEffect(() => {
    setTriggerQuery(true);
  }, []);

  const handleFetchData = () => {
    if (!startDate || !endDate) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Необходимо выбрать период",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }

    if (startDate.isAfter(endDate)) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Начальная дата не может быть позже конечной даты",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }

    setConfirmedStartDate(startDate.toDate().toISOString());
    setConfirmedEndDate(endDate.toDate().toISOString());
    setTriggerQuery(true);
  };

  const generateDatesRange = (start: Dayjs, end: Dayjs): string[] => {
    const dates: string[] = [];
    let currentDate = start.clone();

    while (currentDate.isBefore(end) || currentDate.isSame(end, "day")) {
      dates.push(currentDate.toISOString());
      currentDate = currentDate.add(1, "day");
    }

    return dates;
  };

  const dates = useMemo(
    () =>
      confirmedStartDate && confirmedEndDate
        ? generateDatesRange(dayjs(confirmedStartDate), dayjs(confirmedEndDate))
        : [],
    [confirmedStartDate, confirmedEndDate]
  );

  const handleDateChange = (
    setter: (date: Dayjs | null) => void,
    value: Dayjs | null
  ) => {
    setter(value);
    setConfirmedStartDate(null);
    setConfirmedEndDate(null);
    setTriggerQuery(false);
  };

  const [dataShift, setShift] = useState<Shift[]>([]);
  const [selectedShift, setSelectedShift] = useState<SingleValue<ShiftOption>>({
    value: "all",
    label: "Все смены",
  });

  const shiftOptions: ShiftOption[] = [
    { value: "all", label: "Все смены" },
    ...dataShift.map((shift) => ({
      value: shift.id,
      label: shift.nameshift,
    })),
  ];

  const [dataArea, setArea] = useState<Area[]>([]);
  const [selectedArea, setSelectedArea] =
    useState<SingleValue<AreaOption>>(null);

  const areaOptions: AreaOption[] = dataArea.map((area) => ({
    value: area.id,
    label: area.namearea,
  }));

  const fetchData = useCallback(() => {
    axiosInstance
      .get("/areas")
      .then((response) => {
        const areas = response.data;
        setArea(areas);
      })
      .catch((error) => {
        console.error("Ошибка при выполнении GET-запроса для участков", error);
      });

    axiosInstance
      .get("/shifts")
      .then((response) => {
        const areas = response.data;
        setShift(areas);
      })
      .catch((error) => {
        console.error("Ошибка при выполнении GET-запроса для смен", error);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const isDateInRange = (date) => {
    const today = dayjs().startOf("day");
    const twoDaysAgo = today.subtract(2, "day");
    const oneDayAhead = today.add(1, "day");
    const inputDate = dayjs(date).startOf("day");
    return inputDate.isAfter(twoDaysAgo) && inputDate.isBefore(oneDayAhead);
  };

  const [showModalAdd, setShowModalAdd] = useState(false);
  const [selectIdEmployee, setSelectIdEmployee] = useState("");
  const [selectFioEmployee, setSelectFioEmployee] = useState("");
  const [dateday, setDateday] = useState<Dayjs | null>(dayjs());
  const [typesmen, setTypesmen] = useState("");
  const [numhour, setNumhour] = useState(11);
  const [staz, setStaz] = useState(false);

  const handleAdd = (id, fio) => {
    setShowModalAdd(true);
    setSelectIdEmployee(id);
    setSelectFioEmployee(fio);
    setDateday(null);
    setTypesmen("");
    setNumhour(11);
    setStaz(false);
    setSelectedArea(null);
  };

  const [showModalEdit, setShowModalEdit] = useState(false);
  const handleEdit = (id, fio) => {
    setShowModalEdit(true);
    setSelectIdEmployee(id);
    setSelectFioEmployee(fio);
  };

  const [showModalPremDep, setShowModalPremDep] = useState(false);
  const handlePremDep = (id, fio) => {
    setShowModalPremDep(true);
    setSelectIdEmployee(id);
    setSelectFioEmployee(fio);
  };

  const confirmAdd = async () => {
    if (!dateday || !typesmen || !numhour || !selectedArea) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Необходимо указать все данные",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }

    if (
      localStorage.getItem("role") === "Бригадир" &&
      !isDateInRange(dateday)
    ) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "На эту дату может добавлять смену только менеджер",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }

    if (numhour < 10 || numhour > 11) {
      const confirm = window.confirm(
        `Смена составляет ${Math.round(
          numhour
        )} часов. Вы уверены, что правильно ввели количество часов?`
      );

      if (!confirm) {
        return;
      }
    }

    try {
      setNotification({
        status: "loading",
        title: "Обновление данных",
        subtitle: "Пожалуйста, подождите...",
        subtitle2: <div></div>,
        isVisible: true,
      });

      const formattedDateday = dateday ? dateday.toISOString() : "";

      const TabelDayData = {
        employeeid: selectIdEmployee,
        areaid: selectedArea?.value,
        date: formattedDateday,
        type: typesmen,
        numhours: numhour,
        staz: staz,
        fio_akk: localStorage.getItem("fio_mars"),
        role_akk: localStorage.getItem("role"),
      };

      await axiosInstance.post("/tabel_day/day_create", TabelDayData);
      await refetch();
      setShowModalAdd(false);
      setNotification({
        status: "success",
        title: "Успешно",
        subtitle: "Смена добавлена",
        subtitle2: <div></div>,
        isVisible: true,
      });

      setSelectIdEmployee("");
      setSelectFioEmployee("");
    } catch (error) {
      const errorMessage = error.response?.data || "Ошибка при добавлении";
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: errorMessage,
        subtitle2: <div></div>,
        isVisible: true,
      });
    }
  };

  const options = [
    { value: "day", label: "День" },
    { value: "night", label: "Ночь" },
  ];

  const filteredData = useMemo(() => {
    return Object.keys(formattedData)
      .filter(
        (shift) =>
          selectedShift?.value === "all" || shift === selectedShift?.label
      )
      .map((shift) => {
        const shiftDetails = formattedData[shift];
        return Object.keys(shiftDetails)
          .filter((employeeId) => {
            const employeeName =
              formattedData[shift][employeeId]?.employeeInfo?.fio || "";

            const employeePost =
              formattedData[shift][employeeId]?.employeeInfo?.post || "";
            return employeeName;
          })
          .map((employeeId) => {
            const employeeDetails = shiftDetails[employeeId];
            const {
              fio: employeeName,
              post: employeePost,
              id: emplId,
            } = employeeDetails?.employeeInfo || {};
            let shiftColor = shiftColors[shift] || "white";

            return (
              <tr key={`${shift}-${emplId}`}>
                <td style={{ backgroundColor: "#c0cde0" }}>
                  <IconButton onClick={() => handleAdd(emplId, employeeName)}>
                    <AddIcon sx={{ color: "#384C71", fontSize: 16 }} />
                  </IconButton>
                </td>
                <td style={{ backgroundColor: "#c0cde0" }}>
                  <IconButton onClick={() => handleEdit(emplId, employeeName)}>
                    <CreateIcon sx={{ color: "#384C71", fontSize: 16 }} />
                  </IconButton>
                </td>
                <td style={{ backgroundColor: "#c0cde0" }}>
                  <IconButton
                    onClick={() => handlePremDep(emplId, employeeName)}
                  >
                    <CurrencyExchangeIcon
                      sx={{ color: "#384C71", fontSize: 16 }}
                    />
                  </IconButton>
                </td>
                <td
                  style={{
                    backgroundColor: shiftColor,
                    color: "black",
                  }}
                >
                  {employeePost == 1 ? "Б" : ""}
                </td>
                <td
                  style={{
                    color: "#222F46",
                  }}
                  className="border border-gray-300 px-4 py-2"
                >
                  {employeeName || "Без имени"}
                </td>

                {dates.map((date) => {
                  const dateKey = date;
                  const formattedDateKey = new Date(dateKey)
                    .toISOString()
                    .split("T")[0]; // Приводим dateKey к формату YYYY-MM-DD

                  const records =
                    employeeDetails?.days?.filter((day) => {
                      const dayDate = new Date(day.date)
                        .toISOString()
                        .split("T")[0]; // Приводим дату из days к тому же формату
                      return dayDate === formattedDateKey;
                    }) ?? [];

                  console.log(
                    "Сравнение:",
                    formattedDateKey,
                    records.map(
                      (r) => new Date(r.date).toISOString().split("T")[0]
                    )
                  );

                  if (records.length === 0) {
                    return (
                      <React.Fragment key={date}>
                        <td className="border border-gray-300 px-4 py-2"></td>
                        <td className="border border-gray-300 px-4 py-2"></td>
                      </React.Fragment>
                    );
                  }

                  const dayRecords = records.filter((r) => r.type === "day");
                  const nightRecords = records.filter(
                    (r) => r.type === "night"
                  );

                  return (
                    <React.Fragment key={date}>
                      <td
                        style={{
                          background: dayRecords.some((r) => r.staz)
                            ? "linear-gradient(#3cd500, #fff720)"
                            : dayRecords.some((r) => r.numhours > 0)
                            ? "linear-gradient(#FB8A01, #BAB709)"
                            : "transparent",
                          color: dayRecords.some((r) => r.staz)
                            ? "#233329"
                            : "#9D431D",
                        }}
                      >
                        {dayRecords.map((r, i) => (
                          <div key={i}>{r.numhours}</div>
                        ))}
                      </td>

                      <td
                        style={{
                          background: nightRecords.some((r) => r.staz)
                            ? "linear-gradient(#3cd500, #fff720)"
                            : nightRecords.some((r) => r.numhours > 0)
                            ? "linear-gradient(#022d81, #0b638f)"
                            : "transparent",
                          color: nightRecords.some((r) => r.staz)
                            ? "#233329"
                            : "#d5faff",
                        }}
                      >
                        {nightRecords.map((r, i) => (
                          <div key={i}>{r.numhours}</div>
                        ))}
                      </td>
                    </React.Fragment>
                  );
                })}
              </tr>
            );
          });
      });
  }, [formattedData, selectedShift, dates]);

  const colors = [
    "#efea5a",
    "#f29e4c",
    "#f95738",
    "#efea5a",
    "#9bc53d",
    "#f95738",
    "#f29e4c",
    "#9bc53d",
  ];

  const getColorIndex = (date: string | Date, shift: number = 0) => {
    const baseDate = new Date("2024-12-31");
    const currentDate = new Date(date);
    const diffDays = Math.floor(
      (+currentDate - +baseDate) / (1000 * 60 * 60 * 24)
    );
    return (diffDays * 2 + shift) % colors.length;
  };

  const [selectedColor, setSelectedColor] = useState("");
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedTypeSm, setSelectedTypeSm] = useState("");

  const handleCellClick = (color, date, type) => {
    setSelectedColor(color);
    setSelectedDate(date);
    setSelectedTypeSm(type);
    setIsModalAddAllTabelOpen(true);
  };

  return (
    <div className="page_tabel">
      <div className="page_tabel_container">
        <div className="title-page-container" style={{ marginBottom: "22px" }}>
          <div className="breadcrumbs-container">
            <div className="breadcrumbs-start-item">
              <HomeIcon sx={{ fontSize: 20, color: "#384C71" }} />
              <div className="breadcrumbs-title">Табель</div>
            </div>
            <ArrowForwardIosIcon
              sx={{ fontSize: 13, color: "#384C71", marginLeft: "7px" }}
            />
            <div className="breadcrumbs-item">
              <div className="breadcrumbs-title">
                Табель за определенный период
              </div>
            </div>
          </div>
          <div className="title-page">Табель за определенный период</div>
        </div>

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
          <div className="menu_tabel">
            <DemoContainer
              components={["DatePicker"]}
              sx={{ marginRight: "20px" }}
            >
              <DatePicker
                value={startDate}
                onChange={(value) => handleDateChange(setStartDate, value)}
                label="Начальная дата"
                slotProps={{ textField: { size: "small" } }}
              />
            </DemoContainer>
            <DemoContainer
              components={["DatePicker"]}
              sx={{ marginRight: "20px" }}
            >
              <DatePicker
                value={endDate}
                onChange={(value) => handleDateChange(setEndDate, value)}
                label="Конечная дата"
                slotProps={{ textField: { size: "small" } }}
              />
            </DemoContainer>
            <Button
              size="small"
              onClick={handleFetchData}
              endIcon={<SendIcon />}
              loading={isLoading}
              loadingPosition="end"
              variant="contained"
              sx={{
                backgroundColor: "#384C71",
                height: "40px",
                marginTop: "8px",
                width: "170px",
                "&:hover": {
                  backgroundColor: "#2C3A57",
                },
              }}
            >
              Сформировать
            </Button>

            <Select
              value={selectedShift}
              onChange={(option) => setSelectedShift(option)}
              options={shiftOptions}
              placeholder="Выберите смену"
              isSearchable={false}
              styles={{
                control: (base) => ({
                  ...base,
                  width: "250px",
                  marginTop: "8px",
                  marginLeft: "20px",
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 10,
                }),
              }}
            />
          </div>
        </LocalizationProvider>

        <div
          className="table_tabel_cont"
          style={{
            width: "100%",
            height: "700px",
            overflowY: "auto",
            overflowX: "auto",
          }}
        >
          {!confirmedStartDate || !confirmedEndDate ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                height: "600px",
                alignItems: "center",
                color: "#555",
              }}
            >
              <p>
                Выберите даты и нажмите "Сформировать", чтобы загрузить данные.
              </p>
            </div>
          ) : isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                height: "600px",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : error ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                height: "600px",
                alignItems: "center",
                color: "red",
              }}
            >
              <p>Произошла ошибка при загрузке данных. Попробуйте снова.</p>
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th style={{ border: "none", minWidth: "300px" }}>ФИО</th>
                  {dates.map((date) => (
                    <th key={date} colSpan={2} className="header_tabel_all">
                      <div>{formatDate(date)}</div>
                    </th>
                  ))}
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th style={{ border: "none", minWidth: "300px" }}></th>
                  {dates.map((date, index) => (
                    <React.Fragment key={date}>
                      <th
                        className="day_night_header"
                        style={{
                          backgroundColor: colors[getColorIndex(date)],
                          color: "black",
                          fontWeight: "400",
                          transition:
                            "background-color 0.3s ease, filter 0.3s ease",
                        }}
                        onClick={() =>
                          handleCellClick(
                            colors[getColorIndex(date)],
                            date,
                            "День"
                          )
                        }
                      >
                        <div>День</div>
                      </th>
                      <th
                        className="day_night_header"
                        style={{
                          backgroundColor: colors[getColorIndex(date, 1)],
                          color: "black",
                          fontWeight: "400",
                          transition:
                            "background-color 0.3s ease, filter 0.3s ease",
                        }}
                        onClick={() =>
                          handleCellClick(
                            colors[getColorIndex(date, 1)],
                            date,
                            "Ночь"
                          )
                        }
                      >
                        <div>Ночь</div>
                      </th>
                    </React.Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>{filteredData}</tbody>
            </table>
          )}
        </div>

        <div className="table-all-vac-legend-container">
          <div className="legend-item">
            <div className="color" style={{ backgroundColor: "#D60000" }}></div>
            <div className="title-legend">- красная</div>
          </div>
          <div className="legend-item">
            <div className="color" style={{ backgroundColor: "#FF8C00" }}></div>
            <div className="title-legend">- оранжевая</div>
          </div>
          <div className="legend-item">
            <div className="color" style={{ backgroundColor: "#F9D400" }}></div>
            <div className="title-legend">- желтая</div>
          </div>
          <div className="legend-item">
            <div className="color" style={{ backgroundColor: "#00840D" }}></div>
            <div className="title-legend">- зеленая</div>
          </div>
          <div className="legend-item">
            <div className="color" style={{ backgroundColor: "#6d21b5" }}></div>
            <div className="title-legend">- подработчик</div>
          </div>
          <div className="legend-item">
            <div className="color" style={{ backgroundColor: "#1096e3" }}></div>
            <div className="title-legend">- проект ЧЗ</div>
          </div>
          <div className="legend-item">
            <div className="color" style={{ backgroundColor: "#e310a7" }}></div>
            <div className="title-legend">- отдел rnd</div>
          </div>
          <div className="legend-item">
            <div className="color" style={{ backgroundColor: "#10e3a4" }}></div>
            <div className="title-legend">- петцентр</div>
          </div>
        </div>
      </div>

      {showModalAdd && (
        <div className="modal_tabel_mars">
          <div
            className="modal_tabel_mars_cont"
            style={{ height: "fit-content", width: "fit-content" }}
          >
            <h3 className="title_employee_efes">Добавление смены</h3>
            <label
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "15px",
              }}
            >
              ФИО:{" "}
              <div style={{ textDecoration: "underline", marginLeft: "10px" }}>
                {selectFioEmployee}
              </div>
            </label>

            <div className="tr_cont_select" style={{ marginBottom: "0px" }}>
              <Select
                id="area-dropdown"
                value={selectedArea}
                options={areaOptions}
                onChange={(areaOptions) => setSelectedArea(areaOptions)}
                placeholder="Выберите участок"
                styles={{
                  menu: (base) => ({
                    ...base,
                    zIndex: 10,
                  }),
                }}
              />
            </div>

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
              <DemoContainer
                components={["DatePicker"]}
                sx={{ marginRight: "5px", width: "100%" }}
              >
                <DatePicker
                  value={dateday}
                  onChange={(newValue) => setDateday(newValue)}
                  label="Дата смены"
                  slotProps={{ textField: { size: "small" } }}
                />
              </DemoContainer>
            </LocalizationProvider>

            <Select
              id="electro-dropdown"
              value={options.find((option) => option.value === typesmen)}
              onChange={(selectedOption) =>
                setTypesmen(selectedOption?.value || "")
              }
              options={options}
              placeholder="Выберите тип смены"
              isSearchable={false}
              styles={{
                control: (base) => ({
                  ...base,
                  marginTop: "10px",
                  height: "40px",
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 10,
                }),
              }}
            />

            <TextField
              id="numhour"
              label="Количество часов"
              type="number"
              value={numhour}
              onChange={(e) => setNumhour(Number(e.target.value))}
              InputProps={{ inputProps: { min: 0 } }}
              fullWidth
              sx={{
                marginBottom: "10px",
                marginTop: "10px",
                width: "100%",
              }}
              size="small"
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="main"
                  checked={staz}
                  onChange={(e) => setStaz(e.target.checked)}
                  color="primary"
                />
              }
              label="Стажировка"
            />
            <div className="modal_act_fir">
              <button className="btn_modal_tabel_mars" onClick={confirmAdd}>
                Добавить
              </button>
              <button
                className="btn_modal_tabel_mars"
                onClick={() => setShowModalAdd(false)}
              >
                Отмена
              </button>
            </div>
          </div>
        </div>
      )}

      <ModalAddAllTabel
        isOpen={isModalAddAllTabelOpen}
        onClose={() => setIsModalAddAllTabelOpen(false)}
        selectedColor={selectedColor}
        selectedDate={selectedDate}
        selectedTypeSm={selectedTypeSm}
        refetchData={refetch}
      />

      <ModalEditTabel
        isOpen={showModalEdit}
        onClose={() => setShowModalEdit(false)}
        selectedEmployeeId={selectIdEmployee}
        selectedEmployeeFio={selectFioEmployee}
        refetchData={refetch}
      />

      <ModalAddPremDepTabel
        isOpen={showModalPremDep}
        onClose={() => setShowModalPremDep(false)}
        selectedEmployeeId={selectIdEmployee}
        selectedEmployeeFio={selectFioEmployee}
        refetchData={refetch}
      />

      {notification.isVisible && (
        <Notification
          status={notification.status}
          title={notification.title}
          subtitle={notification.subtitle}
          onClose={hideNotification}
          isVisible={notification.isVisible}
          subtitle2={notification.subtitle2}
        />
      )}
    </div>
  );
};

export default Tabel;
