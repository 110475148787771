import { configureStore } from '@reduxjs/toolkit';
import { employeeActiveSlice } from './Efes/Global/EmployeeActiveSlice.ts';
import { postSlice } from './Efes/Global/PostEfesSlice.ts';
import { historyEfesSlice } from './Efes/Global/HistoryEfesSlice.ts';
import { briefingsEfesSlice } from './Efes/Global/BriefingsEfesSlice.ts';
import { sizEfesSlice } from './Efes/Global/SizEfesSlice.ts';
import { firedEmployeeEfesSlice } from './Efes/Global/FiredEmployeeEfesSlice.ts';
import { normalSizEfesSlice } from './Efes/Global/NormalSizEfesSlice.ts';
import { commissionEfesSlice } from './Efes/Global/CommissionEfesSlice.ts';
import { programBriefEfesSlice } from './Efes/Global/ProgramBriefEfesSlice.ts';
import { allProgrammBriefSlice } from './Efes/Global/AllProgrammBrief.ts';
import { trainingsEfesSlice } from './Efes/Global/TrainingsEfes.ts';
import { employeeEfesReducer } from './Efes/Global/EmployeeActiveSlice.ts';
import { driverEfesSlice } from './Efes/Global/DriverEfesSlice.ts';
import { LMKEmployeeApi } from './Efes/Global/LMKEmployeeEfes.ts';
import { areaSlice } from './Efes/Global/AreaEfesSlice.ts';
import { employeeActiveMarsSlice } from './MarsPets/Global/employeeSlice.ts';
import { briefingsMarsSlice } from './MarsPets/Global/briefingsMarsSlice.ts';
import { firedEmployeeSliceMars } from './MarsPets/Global/firedEmployeeSliceMars.ts';
import { occupancySliceMars } from './MarsPets/Global/occupancySliceMars.ts';
import { vacationsMarsSlice } from './MarsPets/Global/vacationsMarsSlice.ts';
import { statsMonthMarsSlice } from './MarsPets/Global/statsMonthSlice.ts';
import { tabelMarsSlice } from './MarsPets/Global/tabelMarsSlice.ts';
import { trainingSliceMars } from './MarsPets/Global/trainingSliceMars.ts';

export const store = configureStore({
  reducer: {
    [employeeActiveSlice.reducerPath]: employeeActiveSlice.reducer,
    [postSlice.reducerPath]: postSlice.reducer,
    [historyEfesSlice.reducerPath]: historyEfesSlice.reducer,
    [briefingsEfesSlice.reducerPath]: briefingsEfesSlice.reducer,
    [sizEfesSlice.reducerPath]: sizEfesSlice.reducer,
    [firedEmployeeEfesSlice.reducerPath]: firedEmployeeEfesSlice.reducer,
    [normalSizEfesSlice.reducerPath]: normalSizEfesSlice.reducer,
    [commissionEfesSlice.reducerPath]: commissionEfesSlice.reducer,
    [programBriefEfesSlice.reducerPath]: programBriefEfesSlice.reducer,
    [allProgrammBriefSlice.reducerPath]: allProgrammBriefSlice.reducer,
    [trainingsEfesSlice.reducerPath]: trainingsEfesSlice.reducer,
    employeeEfesState: employeeEfesReducer,
    [areaSlice.reducerPath]: areaSlice.reducer,
    [driverEfesSlice.reducerPath]: driverEfesSlice.reducer,
    [LMKEmployeeApi.reducerPath] : LMKEmployeeApi.reducer,

    [employeeActiveMarsSlice.reducerPath] : employeeActiveMarsSlice.reducer,
    [briefingsMarsSlice.reducerPath] : briefingsMarsSlice.reducer,
    [firedEmployeeSliceMars.reducerPath] : firedEmployeeSliceMars.reducer,
    [occupancySliceMars.reducerPath] : occupancySliceMars.reducer,
    [vacationsMarsSlice.reducerPath] : vacationsMarsSlice.reducer,
    [statsMonthMarsSlice.reducerPath] : statsMonthMarsSlice.reducer,
    [tabelMarsSlice.reducerPath] : tabelMarsSlice.reducer,
    [trainingSliceMars.reducerPath] : trainingSliceMars.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(employeeActiveSlice.middleware)
      .concat(postSlice.middleware)
      .concat(historyEfesSlice.middleware)
      .concat(briefingsEfesSlice.middleware)
      .concat(sizEfesSlice.middleware)
      .concat(firedEmployeeEfesSlice.middleware)
      .concat(normalSizEfesSlice.middleware)
      .concat(commissionEfesSlice.middleware)
      .concat(programBriefEfesSlice.middleware)
      .concat(allProgrammBriefSlice.middleware)
      .concat(trainingsEfesSlice.middleware)
      .concat(areaSlice.middleware)
      .concat(driverEfesSlice.middleware)
      .concat(LMKEmployeeApi.middleware)

      .concat(employeeActiveMarsSlice.middleware)
      .concat(briefingsMarsSlice.middleware)
      .concat(firedEmployeeSliceMars.middleware)
      .concat(occupancySliceMars.middleware)
      .concat(vacationsMarsSlice.middleware)
      .concat(statsMonthMarsSlice.middleware)
      .concat(tabelMarsSlice.middleware)
      .concat(trainingSliceMars.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;