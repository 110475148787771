import React, { useState, useEffect } from "react";
import "react-dual-listbox/lib/react-dual-listbox.css";
import DualListBox from "react-dual-listbox";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useGetEmployeeByAreaQuery } from "../Global/trainingSliceMars.ts";
import CircularProgress from "@mui/material/CircularProgress";
import "../Styles/modal_add_all_tabel.scss";
import { format } from "date-fns";
import Notification from "../Components/notification.tsx";
import axiosInstance from "../../axiosConfig.js";
import { EmployeeOption } from "../Global/trainingSliceMars.ts";

const ModalAddAllTabel = ({
  isOpen,
  onClose,
  selectedColor,
  selectedDate,
  selectedTypeSm,
  refetchData,
}) => {
  const [selected, setSelected] = useState([]);
  const { data, isLoading, error } = useGetEmployeeByAreaQuery();

  const onChange = (value) => {
    setSelected(value);
  };

  const [notification, setNotification] = useState({
    status: "",
    title: "",
    subtitle: "",
    subtitle2: <div></div>,
    isVisible: false,
  });

  const hideNotification = () => {
    setNotification({
      ...notification,
      isVisible: false,
    });
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      document
        .querySelectorAll(".rdl-filter")
        .forEach((input) => input.setAttribute("placeholder", "Поиск..."));
    }, 100);

    return () => clearTimeout(interval);
  }, []);

  const handleSave = async () => {
    if (!selected || selected.length === 0) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Необходимо выбрать сотрудников",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }

    try {
      setNotification({
        status: "loading",
        title: "Обновление данных",
        subtitle: "Пожалуйста, подождите...",
        subtitle2: <div></div>,
        isVisible: true,
      });
      const formattedDateday = selectedDate;
      let type = selectedTypeSm === "Ночь" ? "night" : "day";

      const TabelDayData = {
        date: formattedDateday,
        type: type,
        numhours: 11,
        fio_akk: localStorage.getItem("fio_mars"),
        role_akk: localStorage.getItem("role"),
        trainings: selected,
      };

      await axiosInstance.post("/tabel_day/day_create_all", TabelDayData);

      await refetchData();

      hideNotification();

      setSelected([]);
      onClose();
    } catch (error) {
      const errorMessage = error.response?.data || "Ошибка при добавлении";
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: errorMessage,
        subtitle2: <div></div>,
        isVisible: true,
      });
    }
  };

  const formatDate = (dateStr: string) => {
    const parsedDate = new Date(dateStr);
    return format(parsedDate, "dd.MM.yyyy");
  };

  const resetForm = () => {
    setSelected([]);
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  if (!isOpen) return null;

  if (isLoading)
    return (
      <div className="modal_tabel_mars">
        <div className="modal_tabel_mars_cont">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <div style={{ marginBottom: "20px" }}>
              Загрузка данных, подождите
            </div>
            <CircularProgress />
          </div>
        </div>
      </div>
    );

  if (error)
    return (
      <div className="modal_tabel_mars">
        <div className="modal_tabel_mars_cont">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                marginBottom: "20px",
                color: "red",
                textAlign: "center",
              }}
            >
              Ошибка, перезагрузите или выйдите и зайдите в систему еще раз
            </div>
            <img
              src={require("../Assets/error.svg").default}
              alt=""
              width={"80px"}
            />
          </div>
          <button onClick={onClose}>Закрыть</button>
        </div>
      </div>
    );

  return (
    <div className="modal_tabel_mars">
      <div className="modal_tabel_mars_cont">
        <h3 className="title_employee_efes">Добавление смены</h3>
        <label
          style={{
            marginBottom: "15px",
          }}
        >
          Дата: {formatDate(selectedDate)}
        </label>
        <label
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "15px",
          }}
        >
          Смена:
          <div
            style={{
              backgroundColor: selectedColor,
              marginLeft: "5px",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            {selectedTypeSm}
          </div>
        </label>

        <DualListBox
          canFilter
          id="filter"
          options={data ?? []}
          selected={selected}
          onChange={onChange}
          icons={{
            moveLeft: <span className="fa fa-chevron-left" />,
            moveAllLeft: null,
            moveRight: <span className="fa fa-chevron-right" />,
            moveAllRight: null,
            moveDown: <span className="fa fa-chevron-down" />,
            moveUp: <span className="fa fa-chevron-up" />,
            moveTop: <span className="fa fa-double-angle-up" />,
            moveBottom: <span className="fa fa-double-angle-down" />,
          }}
        />
        <div className="modal_act_fir">
          <button className="btn_modal_tabel_mars" onClick={handleSave}>
            Сохранить
          </button>
          <button className="btn_modal_tabel_mars" onClick={handleClose}>
            Закрыть
          </button>
        </div>
      </div>

      {notification.isVisible && (
        <Notification
          status={notification.status}
          title={notification.title}
          subtitle={notification.subtitle}
          onClose={hideNotification}
          isVisible={notification.isVisible}
          subtitle2={notification.subtitle2}
        />
      )}
    </div>
  );
};

export default ModalAddAllTabel;
