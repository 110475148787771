import React, { useState } from "react";
import '../Styles/login.scss';
import axiosInstance from "../../axiosConfig";
import { useJwt } from "react-jwt";
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post("/login", {
        username,
        password,
      });

      if (response.status === 200 && response.data && response.data.token) {
        console.log("Успешная авторизация");

        // Сохранение данных авторизации
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("role", response.data.role);
        localStorage.setItem("fio_mars", response.data.fio);
        console.log(response.data.fio);

        // Перенаправление после успешной авторизации
        navigate("/marspets/", { replace: true });
      } else {
        setError("Неверный ответ от сервера");
        console.log(error)
      }
    } catch (err) {
      console.error("Ошибка при авторизации:", err);
      setError("Неверное имя пользователя или пароль");
      console.log(error)
    }
  };

  return (
    <div className="login_start_container">
      <a href="/" className="button-34">🠔 К выбору объекта</a>
      <div className="login-page">
      <img src="https://infopro54.ru/wp-content/uploads/2021/06/Mars-Petcare-lockup-RGB.png" alt="" width="200px" height="150px" style={{objectFit:"contain"}}/>
        <div className="form">
          <form className="login-form" onSubmit={handleLogin}>
            <input
              type="text"
              placeholder="Логин"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Пароль"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button className="button-65" type="submit">Войти</button>
            {error && <p className="error" style={{color: "red", marginBottom: "0px"}}>{error}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;