import React, { useState, useEffect } from "react";
import axiosInstance from "../../axiosConfig";
import "../Styles/vacations_all.scss";
import { Select, MenuItem, FormControl, InputLabel, OutlinedInput, Checkbox, FormControlLabel, CircularProgress } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { useGetAllVacationsMarsQuery } from "../Global/vacationsMarsSlice.ts";
import { useNavigate } from 'react-router-dom';
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import AddIcon from '@mui/icons-material/Add';
import Notification from "../Components/notification.tsx";
import { Employees } from "./table_employee.tsx";
import TextField from '@mui/material/TextField';

interface Vacation {
  employeeid: Employees;
  datestart: string | null;
  dateend: string | null;
}

const AllVacations = () => {
  const navigate = useNavigate();
  const [year, setYear] = useState<number>(2025);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [selectedShift, setSelectedShift] = useState<string>("Все");
  const [showAllEmployees, setShowAllEmployees] = useState<boolean>(false);
  const [onlyNoVacations, setOnlyNoVacations] = useState<boolean>(false);

  const monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

  const handleMonthClick = (monthIndex: number) => {
    navigate(`/marspets/vacations/${year}/${monthIndex}`);
  };

  const shiftColors: { [key: string]: string } = {
    "Красная": "#D60000",
    "Оранжевая": "#FF8C00",
    "Желтая": "#F9D400",
    "Зеленая": "#00840D",
    "Подработчик": "#6d21b5",
    "Проект ЧЗ": "#5c5c5c",
    "Отдел rnd": "#E2E8F1",
    "Петцентр": "#E2E8F1",
    "Декрет": "#E2E8F1",
    "СВО": "#E2E8F1",
  };

  const [notification, setNotification] = useState({
    status: "",
    title: "",
    subtitle: "",
    subtitle2: <div></div>,
    isVisible: false,
  });
  
  const hideNotification = () => {
    setNotification({
      ...notification,
      isVisible: false,
    });
  };

  const { data, error, isFetching, refetch } = useGetAllVacationsMarsQuery(year, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isFetching) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isFetching]);

  const calculateMonthlyVacations = () => {
    if (!data || typeof data !== "object") {
      return { rows: [], monthlyTotals: new Array(12).fill(0), vacationCounts: new Array(12).fill(0) };
    }
  
    const monthlyTotals = new Array(12).fill(0);
    const vacationCounts = new Array(12).fill(0);
  
    const rows = Object.entries(data).flatMap(([shift, shiftDetails]) =>
      Object.entries(shiftDetails).map(([name, details]) => {
        const monthlyCounts = new Array(12).fill(0);
        const id = details.id; 
  
        if (details.dates && Array.isArray(details.dates)) {
          details.dates.forEach((date) => {
            const d = new Date(date);
            const month = d.getMonth();
            monthlyCounts[month]++;
            monthlyTotals[month]++;
          });
  
          monthlyCounts.forEach((count, index) => {
            if (count > 0) vacationCounts[index]++;
          });
        }
  
        return { id, name, shift, monthlyCounts };
      })
    );
  
    return { rows, monthlyTotals, vacationCounts };
  };
  
  

  const { rows, monthlyTotals, vacationCounts } = calculateMonthlyVacations();

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredRows = rows.filter((row) => {
    const hasVacations = row.monthlyCounts.some((count) => count > 0);
    if (selectedShift !== "Все" && row.shift !== selectedShift) return false;
    if (searchTerm && !row.name.toLowerCase().includes(searchTerm.toLowerCase())) return false;
    if (onlyNoVacations) return !hasVacations;
    if (showAllEmployees) return true;
    return hasVacations;
  });


  const [showModalAdd, setShowModalAdd] = useState(false);
  const [selectIdEmployee, setSelectIdEmployee] = useState("");
  const [selectFioEmployee, setSelectFioEmployee] = useState("");
  const [datestart, setDateStart] = useState("");
  const [dateend, setDateEnd] = useState("");

  const handleAdd = (id, fio) => {
    setShowModalAdd(true);
    setSelectIdEmployee(id);
    setSelectFioEmployee(fio);
    console.log(selectIdEmployee);
    setDateStart("");
    setDateEnd("");
  }

  const confirmAdd = async () => {
    if (!datestart || !dateend) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Необходимо указать все данные",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }
    const formattedStart = new Date(datestart);
    const formattedEnd = new Date(dateend);

    if (formattedEnd < formattedStart) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Дата окончания не может быть раньше даты начала",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }

    const differenceInDays =
      (formattedEnd.getTime() - formattedStart.getTime()) / (1000 * 60 * 60 * 24);

    if (differenceInDays > 14) {
      const confirm = window.confirm(
        `Длительность отпуска составляет ${Math.round(
          differenceInDays
        )} дней. Вы уверены, что правильно ввели даты?`
      );

      if (!confirm) {
        return;
      }
    }

    try {
      const formattedStartISO = formattedStart.toISOString();
      const formattedEndISO = formattedEnd.toISOString();

      const VacData = {
        employeeid: selectIdEmployee,
        datestart: formattedStartISO,
        dateend: formattedEndISO,
      };

      await axiosInstance.post<Vacation>(
        "/vacations/vacations_create", VacData
      );

      setShowModalAdd(false);
      setNotification({
        status: "success",
        title: "Успешно",
        subtitle: "Отпуск добавлен",
        subtitle2: <div></div>,
        isVisible: true,
      });
      refetch();
    } catch (error) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Ошибка при добавлении отпуска",
        subtitle2: <div></div>,
        isVisible: true,
      });
    }
  };

  return (
    <div className="table-all-vac">
      <div className="table-all-vac-container">
        <div className="title-page-container">
          <div className="breadcrumbs-container">
            <div className="breadcrumbs-start-item">
              <HomeIcon sx={{ fontSize: 20, color: "#384C71" }} />
              <div className="breadcrumbs-title">Отпуск</div>
            </div>
            <ArrowForwardIosIcon sx={{ fontSize: 13, color: "#384C71", marginLeft: "7px" }} />
            <div className="breadcrumbs-item">
              <div className="breadcrumbs-title">График отпусков за год</div>
            </div>
          </div>
          <div className="title-page">График отпусков за год</div>
        </div>

        {/* Фильтры */}
        <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
          <FormControl style={{ minWidth: 200 }} size="small">
            <Select
              value={year}
              onChange={(e) => setYear(Number(e.target.value))}
            >
              {[2023, 2024, 2025, 2026].map((yr) => (
                <MenuItem key={yr} value={yr}>{yr}</MenuItem>
              ))}
            </Select>
          </FormControl>


          {/* Выбор смены */}
          <FormControl style={{ minWidth: 200 }} size="small">
            <Select
              value={selectedShift}
              onChange={(e) => setSelectedShift(e.target.value)}
            >
              <MenuItem value="Все">Все смены</MenuItem>
              {Object.keys(shiftColors).map((shift) => (
                <MenuItem key={shift} value={shift}>{shift}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Чекбокс "Отобразить всех сотрудников" */}
          <FormControlLabel
            control={<Checkbox checked={showAllEmployees} onChange={() => setShowAllEmployees(!showAllEmployees)} />}
            label="Отобразить всех сотрудников"
          />

          <FormControlLabel
            control={<Checkbox checked={onlyNoVacations} onChange={() => setOnlyNoVacations(!onlyNoVacations)} />}
            label="Нет отпусков"
          />

          <TextField
            label="Поиск по ФИО"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{ minWidth: 200 }}
          />
        </div>

        {/* Таблица */}
        <div className="table_all_vac_cont" style={{ width: "100%", height: "700px", overflowY: "auto", overflowX: "auto" }}>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px", height: "600px", alignItems: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <table className="vacation-table">
              <thead>
                <tr>
                  <th style={{ backgroundColor: "#384C71", borderColor: "#8FA5BF", color: "#8FA5BF" }}></th>
                  <th style={{ backgroundColor: "#384C71", borderColor: "#8FA5BF", color: "#8FA5BF" }}>ФИО</th>
                  {monthNames.map((monthName, index) => (
                    <th className="month-item" key={index} style={{ textAlign: "center", borderColor: "#8FA5BF", color: "#8FA5BF"}} onClick={() => handleMonthClick(index)}>
                      {monthName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredRows.map((row, rowIndex) => (
                  <tr
                    key={row.name}
                    onClick={() => setSelectedRow(rowIndex)}
                    className={selectedRow === rowIndex ? "selected-row" : ""}
                    style={{ backgroundColor: row.monthlyCounts.every(count => count === 0) ? "#f0f0f0" : "inherit" }}
                  >
                    <td style={{ backgroundColor: "#c0cde0" }}>
                      <IconButton onClick={() => handleAdd(row.id, row.name)}>
                        <AddIcon sx={{ color: "#384C71", fontSize: 16 }} />
                      </IconButton>
                    </td>
                    <td style={{ backgroundColor: shiftColors[row.shift] || "white" }}>{row.name}</td>
                    {row.monthlyCounts.map((count, monthIndex) => (
                      <td key={monthIndex} style={{ textAlign: "center", backgroundColor: count > 0 ? "#d1ffd6" : "" }}>
                        {count > 0 ? count : ""}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td style={{ backgroundColor: "#384C71", borderColor: "#8FA5BF", color: "#8FA5BF" }}></td>
                  <td style={{ backgroundColor: "#384C71", borderColor: "#8FA5BF", color: "#8FA5BF" }}><strong>Итого</strong></td>
                  {monthlyTotals.map((total, index) => (
                    <td key={index} style={{ backgroundColor: "#384C71", textAlign: "center", borderColor: "#8FA5BF", color: "#8FA5BF" }}>
                      {total} дн. / {vacationCounts[index]} отпусков
                    </td>
                  ))}
                </tr>
              </tfoot>
            </table>
          )}
        </div>

        <div className="table-all-vac-legend-container">
          <div className="legend-item">
            <div className="color" style={{backgroundColor: "#D60000"}}></div>
            <div className="title-legend">- красная</div>
          </div>
          <div className="legend-item">
            <div className="color" style={{backgroundColor: "#FF8C00"}}></div>
            <div className="title-legend">- оранжевая</div>
          </div>
          <div className="legend-item">
            <div className="color" style={{backgroundColor: "#F9D400"}}></div>
            <div className="title-legend">- желтая</div>
          </div>
          <div className="legend-item">
            <div className="color" style={{backgroundColor: "#00840D"}}></div>
            <div className="title-legend">- зеленая</div>
          </div>
          <div className="legend-item">
            <div className="color" style={{backgroundColor: "#6d21b5"}}></div>
            <div className="title-legend">- подработчик</div>
          </div>
          <div className="legend-item">
            <div className="color" style={{backgroundColor: "#5c5c5c"}}></div>
            <div className="title-legend">- проект ЧЗ</div>
          </div>
          <div className="legend-item">
            <div className="color" style={{backgroundColor: "#E2E8F1"}}></div>
            <div className="title-legend">- отдел rnd, петцентр, декрет, СВО</div>
          </div>
        </div>
      </div>

      {showModalAdd && (
        <div className="modal_brief_employee_efes">
          <div className="modal_cont_brief_employee_efes" style={{height:"fit-content", width: "fit-content"}}>
              <h3 className='title_employee_efes'>Добавление отпуска</h3>
              <label style={{display:"flex", flexDirection: "row", marginBottom: "15px"}}>ФИО: <div style={{textDecoration: "underline", marginLeft: "10px"}}>{selectFioEmployee}</div></label>

              <div className="dat">
                  <label htmlFor="datestart">Дата начала:</label>
                  <input
                      type="date"
                      id="datestart"
                      name="datestart"
                      value={datestart}
                      onChange={(e) => setDateStart(e.target.value)}
                      style={{ marginBottom: "10px", marginLeft: "75px", width: "225px"}}
                  />
              </div>

              <div className="dat">
                  <label htmlFor="dateend">Дата окончания:</label>
                  <input
                      type="date"
                      id="dateend"
                      name="dateend"
                      value={dateend}
                      onChange={(e) => setDateEnd(e.target.value)}
                      style={{ marginBottom: "10px", marginLeft: "43px"}}
                  />
              </div>

              <div className="modal_act_fir">
                  <button onClick={confirmAdd}>Добавить</button>
                  <button onClick={() => setShowModalAdd(false)}>Отмена</button>
              </div>
          </div>
        </div>
      )}

      {notification.isVisible && (
          <Notification
              status={notification.status}
              title={notification.title}
              subtitle={notification.subtitle}
              onClose={hideNotification}
              isVisible={notification.isVisible}
              subtitle2={notification.subtitle2}
          />
      )}
    </div>
  );
};

export default AllVacations;

