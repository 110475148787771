import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./marsBaseQuery.ts";

interface EmployeeByArea {
  label: string;
  options: EmployeeOption[];
}

export interface EmployeeOption {
  value: string;
  label: string;
}

export const trainingSliceMars = createApi({
  reducerPath: "trainingMars",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["TrainingMars"],
  endpoints: (builder) => ({
    getEmployeeByArea: builder.query<EmployeeByArea, void>({
      query: () => ({
        url: `/trainings/grouped_employees`,
        method: "GET",
      }),
      providesTags: ["TrainingMars"],
    }),
  }),
});

export const { useGetEmployeeByAreaQuery } = trainingSliceMars;
