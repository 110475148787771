import React, { useState, useEffect, useCallback } from "react";
import Notification from "../Components/notification.tsx";
import { useGetTabelByEmployeeQuery } from "../Global/tabelMarsSlice.ts";
import Select, { SingleValue } from "react-select";
import { Checkbox, FormControlLabel } from "@mui/material";
import { TextField, CircularProgress } from "@mui/material";
import axiosInstance from "../../axiosConfig.js";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { format } from "date-fns";

interface SmenOption {
  value: number;
  label: string;
}

interface AreaOption {
  value: number;
  label: string;
}

interface Area {
  id: number;
  namearea: string;
}

const ModalEditTabel = ({
  isOpen,
  onClose,
  selectedEmployeeId,
  selectedEmployeeFio,
  refetchData,
}) => {
  const [notification, setNotification] = useState({
    status: "",
    title: "",
    subtitle: "",
    subtitle2: <div></div>,
    isVisible: false,
  });
  const hideNotification = () => {
    setNotification({
      ...notification,
      isVisible: false,
    });
  };

  const { data, isLoading, error, refetch } = useGetTabelByEmployeeQuery(
    { employeeId: selectedEmployeeId },
    { skip: !selectedEmployeeId }
  );

  const [dateday, setDateday] = useState<Dayjs | null>(null);
  const [typesmen, setTypesmen] = useState("");
  const [numhour, setNumhour] = useState("11");
  const [staz, setStaz] = useState(false);
  const [smenid, setSmenid] = useState<number | null>(null);

  const [selectedSmen, setSelectedSmen] =
    useState<SingleValue<SmenOption>>(null);
  const [dataArea, setArea] = useState<Area[]>([]);
  const [selectedArea, setSelectedArea] =
    useState<SingleValue<AreaOption>>(null);

  const options = [
    { value: "day", label: "День" },
    { value: "night", label: "Ночь" },
  ];

  const formatDate = (dateStr: string) => {
    const parsedDate = new Date(dateStr);
    return format(parsedDate, "dd.MM.yyyy");
  };

  const smenOptions: SmenOption[] =
    data?.map((smen) => ({
      value: smen.id,
      label: `${formatDate(smen.date)} - ${
        smen.type === "night" ? "Ночь" : "День"
      } - ${smen.area.namearea}`,
    })) || [];

  const areaOptions: AreaOption[] = dataArea.map((area) => ({
    value: area.id,
    label: area.namearea,
  }));

  useEffect(() => {
    if (!isOpen) return;
    refetch();
    axiosInstance
      .get("/areas")
      .then((response) => setArea(response.data))
      .catch((error) => console.error("Ошибка при загрузке участков", error));
  }, [isOpen]);

  useEffect(() => {
    if (!selectedSmen || !data) return;

    const selectedData = data.find((item) => item.id === selectedSmen.value);
    if (!selectedData) return;

    setSmenid(selectedData.id);
    setDateday(dayjs(selectedData.date));
    setTypesmen(selectedData.type);
    setNumhour(String(selectedData.numhours));
    setStaz(selectedData.staz);

    setSelectedArea(
      areaOptions.find((area) => area.value === selectedData.area.id) || null
    );
  }, [selectedSmen, data]);

  const isDateInRange = (date) => {
    const today = dayjs().startOf("day");
    const twoDaysAgo = today.subtract(2, "day");
    const oneDayAhead = today.add(1, "day");
    const inputDate = dayjs(date).startOf("day");
    return inputDate.isAfter(twoDaysAgo) && inputDate.isBefore(oneDayAhead);
  };

  const confirmEdit = async () => {
    if (!dateday || !typesmen || !numhour || !selectedArea) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Необходимо указать все данные",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }

    if (
      localStorage.getItem("role") === "Бригадир" &&
      !isDateInRange(dateday)
    ) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Эту смену может редактировать только менеджер",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }

    if (Number(numhour) < 10 || Number(numhour) > 11) {
      const confirm = window.confirm(
        `Смена составляет ${Math.round(
          Number(numhour)
        )} часов. Вы уверены, что правильно ввели количество часов?`
      );

      if (!confirm) {
        return;
      }
    }

    try {
      setNotification({
        status: "loading",
        title: "Обновление данных",
        subtitle: "Пожалуйста, подождите...",
        subtitle2: <div></div>,
        isVisible: true,
      });

      const formattedDateday = dateday ? dateday.toISOString() : "";

      const TabelDayData = {
        id: smenid,
        areaid: selectedArea?.value,
        date: formattedDateday,
        type: typesmen,
        numhours: numhour,
        staz: staz,
        fio_akk: localStorage.getItem("fio_mars"),
        role_akk: localStorage.getItem("role"),
      };

      await axiosInstance.post("/tabel_day/day_edit", TabelDayData);

      await refetchData();

      setSmenid(Number(null));
      setDateday(null);
      setTypesmen("");
      setNumhour(String(null));
      setStaz(false);
      setSelectedArea(null);
      setSelectedSmen(null);

      hideNotification();

      onClose();
    } catch (error) {
      const errorMessage = error.response?.data || "Ошибка при редактировании";
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: errorMessage,
        subtitle2: <div></div>,
        isVisible: true,
      });
    }
  };

  const resetForm = () => {
    setSelectedSmen(null);
    setSmenid(Number(null));
    setDateday(dayjs());
    setTypesmen("");
    setNumhour(String(null));
    setStaz(false);
    setSelectedArea(null);
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  if (!isOpen) return null;

  if (isLoading)
    return (
      <div className="modal_tabel_mars">
        <div className="modal_tabel_mars_cont">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <div style={{ marginBottom: "20px" }}>
              Загрузка данных, подождите
            </div>
            <CircularProgress />
          </div>
        </div>
      </div>
    );

  if (error)
    return (
      <div className="modal_tabel_mars">
        <div className="modal_tabel_mars_cont">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                marginBottom: "20px",
                color: "red",
                textAlign: "center",
              }}
            >
              Ошибка, перезагрузите или выйдите и зайдите в систему еще раз
            </div>
            <img
              src={require("../Assets/error.svg").default}
              alt=""
              width={"80px"}
            />
          </div>
          <button onClick={onClose}>Закрыть</button>
        </div>
      </div>
    );

  return (
    <div className="modal_tabel_mars">
      <div className="modal_tabel_mars_cont">
        <h3 className="title_employee_efes">Редактирование смен</h3>
        <label
          style={{
            marginBottom: "15px",
          }}
        >
          ФИО: {selectedEmployeeFio}
        </label>

        <Select
          id="mentor-dropdown"
          value={selectedSmen}
          options={smenOptions}
          onChange={(smenOptions) => setSelectedSmen(smenOptions)}
          placeholder="Выберите смену"
          styles={{
            menu: (base) => ({
              ...base,
              zIndex: 10,
            }),
          }}
        />

        {!selectedSmen ? (
          <div
            style={{
              textAlign: "center",
              fontSize: "16px",
              width: "100%",
              marginTop: "20px",
              color: "grey",
              marginBottom: "10px",
            }}
          >
            Необходимо выбрать смену
          </div>
        ) : isLoading ? (
          <div
            style={{
              textAlign: "center",
              fontSize: "16px",
              width: "100%",
              marginTop: "20px",
              color: "grey",
              marginBottom: "10px",
            }}
          >
            ⏳ Загрузка данных...
          </div>
        ) : (
          <div style={{ marginTop: "10px" }}>
            <div className="tr_cont_select" style={{ marginBottom: "10px" }}>
              <Select
                id="area-dropdown"
                value={selectedArea}
                options={areaOptions}
                onChange={(areaOptions) => setSelectedArea(areaOptions)}
                placeholder="Выберите участок"
                styles={{
                  menu: (base) => ({
                    ...base,
                    zIndex: 10,
                  }),
                }}
              />
            </div>

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
              <DemoContainer
                components={["DatePicker"]}
                sx={{ marginRight: "5px", width: "100%", marginBottom: "5px" }}
              >
                <DatePicker
                  value={dateday}
                  onChange={(newValue) => setDateday(newValue)}
                  label="Дата смены"
                  slotProps={{ textField: { size: "small" } }}
                />
              </DemoContainer>
            </LocalizationProvider>

            <Select
              id="electro-dropdown"
              value={options.find((option) => option.value === typesmen)}
              onChange={(selectedOption) =>
                setTypesmen(selectedOption?.value || "")
              }
              options={options}
              placeholder="Выберите тип смены"
              isSearchable={false}
              styles={{
                control: (base) => ({
                  ...base,
                  marginTop: "10px",
                  height: "40px",
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 10,
                }),
              }}
            />
            <div style={{ marginTop: "10px" }}>
              <TextField
                id="numhour"
                label="Количество часов"
                type="number"
                value={numhour}
                onChange={(e) => setNumhour(e.target.value)}
                fullWidth
                sx={{
                  marginBottom: "10px",
                  marginTop: "10px",
                  width: "100%",
                }}
                size="small"
              />
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  id="main"
                  checked={staz}
                  onChange={(e) => setStaz(e.target.checked)}
                  color="primary"
                />
              }
              label="Стажировка"
            />
          </div>
        )}

        <div className="modal_act_fir">
          <button className="btn_modal_tabel_mars" onClick={confirmEdit}>
            Сохранить
          </button>
          <button className="btn_modal_tabel_mars" onClick={handleClose}>
            Закрыть
          </button>
        </div>
      </div>

      {notification.isVisible && (
        <Notification
          status={notification.status}
          title={notification.title}
          subtitle={notification.subtitle}
          onClose={hideNotification}
          isVisible={notification.isVisible}
          subtitle2={notification.subtitle2}
        />
      )}
    </div>
  );
};

export default ModalEditTabel;
