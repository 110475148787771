import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl, InputLabel, OutlinedInput, Checkbox, FormControlLabel, CircularProgress } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button";
import axiosInstance from "../../../axiosConfig";
import Notification from "../../Components/notification.tsx";
import { useGetStatsMonthMarsQuery } from "../../Global/statsMonthSlice.ts";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, Cell, ResponsiveContainer } from "recharts";

const StatsSave = () => {
    const [notification, setNotification] = useState({
        status: "",
        title: "",
        subtitle: "",
        subtitle2: <div></div>,
        isVisible: false,
    });

    const hideNotification = () => {
        setNotification({
            ...notification,
            isVisible: false,
        });
    };

    const { data , error, isLoading, refetch } = useGetStatsMonthMarsQuery();
    // Преобразуем данные для графика
    const transformedData = data?.flatMap((entry) => [
        { datesave: entry.datesave, category: "Возраст 18-29", Мужчины: entry.age1m, Женщины: entry.age1f },
        { datesave: entry.datesave, category: "Возраст 30-39", Мужчины: entry.age2m, Женщины: entry.age2f },
        { datesave: entry.datesave, category: "Возраст 40-49", Мужчины: entry.age3m, Женщины: entry.age3f },
        { datesave: entry.datesave, category: "Возраст 50-59", Мужчины: entry.age4m, Женщины: entry.age4f },
        { datesave: entry.datesave, category: "Возраст 60+", Мужчины: entry.age5m, Женщины: entry.age5f },
        { datesave: entry.datesave, category: "Всего", Мужчины: entry.allagem, Женщины: entry.allagef },
      ]);

  
    // Функция для обработки сохранения статистики
    const handleSave = async () => {
        try {
            const response = await axiosInstance.post("/stats_mars/save");
    
            if (response.status === 200) {
                setNotification({
                    status: "success",
                    title: "Успешно",
                    subtitle: "Статистика сохранена",
                    subtitle2: <div></div>,
                    isVisible: true,
                });
                refetch();
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 400 && error.response.data === "Статистика была сохранена менее недели назад.") {
                    setNotification({
                        status: "error",
                        title: "Ошибка",
                        subtitle: error.response.data,
                        subtitle2: <div></div>,
                        isVisible: true,
                    });
                } else {
                    setNotification({
                        status: "error",
                        title: "Ошибка при сохранении статистики",
                        subtitle: "Не удалось сохранить статистику",
                        subtitle2: <div></div>,
                        isVisible: true,
                    });
                }
            } else if (error.request) {
                setNotification({
                    status: "error",
                    title: "Ошибка при подключении к серверу",
                    subtitle: "Нет ответа от сервера",
                    subtitle2: <div></div>,
                    isVisible: true,
                });
            } else {
                setNotification({
                    status: "error",
                    title: "Неизвестная ошибка",
                    subtitle: "Произошла ошибка при отправке запроса",
                    subtitle2: <div></div>,
                    isVisible: true,
                });
            }
        }
    };
  
    // Функция для отображения модального окна подтверждения
    const handleButtonClick = () => {
      const isConfirmed = window.confirm("Вы точно хотите сохранить статистику за сегодняшний день?");
      if (isConfirmed) {
        handleSave();
      }
    };

    const colors = {
        Зеленый: "#00840D",
        Оранжевый: "#FF8C00",
        Красный: "#D60000",
        Желтый: "#F9D400",
        Декрет: "#b0b4ba",
        РнД: "#b0b4ba",
        Пэт: "#b0b4ba",
        Подработки: "#320E54",
        СВО: "#b0b4ba",
        ПроектЧЗ: "#000000", 
        Всего: "#384C71"
    };

    if (isLoading)
        return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px"}}>Загрузка данных, подождите</div>
            <CircularProgress />
          </div>
        </div>
    ;
          
    if (error) return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "300px", height: "150px"}}>
            <div style={{marginBottom: "20px", color: "red", textAlign: "center"}}>Ошибка, перезагрузите или выйдите и зайдите в систему еще раз</div>
            <img src={require("../../Assets/error.svg").default} alt="" width={"80px"} />
            </div>
        </div>
    ;
  
    return (
      <div className="table-all-vac" style={{height: "1500px"}}>
        <div className="table-all-vac-container">
            <div className="title-page-container">
                <div className="breadcrumbs-container">
                <div className="breadcrumbs-start-item">
                    <HomeIcon sx={{ fontSize: 20, color: "#384C71" }} />
                    <div className="breadcrumbs-title">Статистика</div>
                </div>
                <ArrowForwardIosIcon sx={{ fontSize: 13, color: "#384C71", marginLeft: "7px" }} />
                <div className="breadcrumbs-item">
                    <div className="breadcrumbs-title">Статистика за год</div>
                </div>
                </div>
                <div className="title-page">Статистика за год</div>
            </div>
  
            <Button
                variant="contained"
                sx={{
                width: "fit-content",
                backgroundColor: "#384C71",
                color: "white",
                fontFamily: "'PT Sans', sans-serif",
                fontSize: "16px", 
                fontWeight: "400",
                textTransform: "none", 
                "&:hover": {
                    backgroundColor: "#222F46" 
                }
                }}
                onClick={handleButtonClick}
            >
                Сохранить статистику за сегодняшний день
            </Button>

            <div className="container_grafs">

                <div className="block_employee_barchat_shift" style={{width: "100%", marginLeft: "0px", fontWeight: "400"}}>
                    <div style={{fontWeight: "700", fontSize: "18px"}} >Наполняемость смен</div>
                    <ResponsiveContainer width="90%" height={300}>
                        <BarChart
                            data={data}
                            margin={{ top: 20, right: 30, bottom: 5, left: 20 }}
                        >
                            <XAxis dataKey="datesave" />
                            <YAxis />
                            <Tooltip />
                            <Legend />

                            {/* Бар для данных Красный */}
                            <Bar
                                dataKey="red"
                                name="Красный"
                                fill={colors.Красный}
                                label={{ position: "top" }}
                            >
                                {data?.map((entry, index) => (
                                    <Cell key={`cell-red-${index}`} fill={colors.Красный} />
                                ))}
                            </Bar>

                            {/* Бар для данных Оранжевый */}
                            <Bar
                                dataKey="orange"
                                name="Оранжевый"
                                fill={colors.Оранжевый}
                                label={{ position: "top" }}
                            >
                                {data?.map((entry, index) => (
                                    <Cell key={`cell-orange-${index}`} fill={colors.Оранжевый} />
                                ))}
                            </Bar>

                            {/* Бар для данных Желтый */}
                            <Bar
                                dataKey="yellow"
                                name="Желтый"
                                fill={colors.Желтый}
                                label={{ position: "top" }}
                            >
                                {data?.map((entry, index) => (
                                    <Cell key={`cell-yellow-${index}`} fill={colors.Желтый} />
                                ))}
                            </Bar>

                            {/* Бар для данных Зеленый */}
                            <Bar
                                dataKey="green"
                                name="Зеленый"
                                fill={colors.Зеленый}
                                label={{ position: "top" }}
                            >
                                {data?.map((entry, index) => (
                                    <Cell key={`cell-green-${index}`} fill={colors.Зеленый} />
                                ))}
                            </Bar>

                            <Bar
                                dataKey="podrab"
                                name="Подработчик"
                                fill={colors.Подработки}
                                label={{ position: "top" }}
                            >
                                {data?.map((entry, index) => (
                                    <Cell key={`cell-podrab-${index}`} fill={colors.Подработки} />
                                ))}
                            </Bar>

                            <Bar
                                dataKey="projectchz"
                                name="ПроектЧЗ"
                                fill={colors.ПроектЧЗ}
                                label={{ position: "top" }}
                            >
                                {data?.map((entry, index) => (
                                    <Cell key={`cell-projectchz-${index}`} fill={colors.ПроектЧЗ} />
                                ))}
                            </Bar>

                            <Bar
                                dataKey="rnd"
                                name="Отдел rnd"
                                fill={colors.РнД}
                                label={{ position: "top" }}
                            >
                                {data?.map((entry, index) => (
                                    <Cell key={`cell-rnd-${index}`} fill={colors.РнД} />
                                ))}
                            </Bar>

                            <Bar
                                dataKey="pet"
                                name="Петцентр"
                                fill={colors.Пэт}
                                label={{ position: "top" }}
                            >
                                {data?.map((entry, index) => (
                                    <Cell key={`cell-pet-${index}`} fill={colors.Пэт} />
                                ))}
                            </Bar>

                            <Bar
                                dataKey="decret"
                                name="Декрет"
                                fill={colors.Декрет}
                                label={{ position: "top" }}
                            >
                                {data?.map((entry, index) => (
                                    <Cell key={`cell-decret-${index}`} fill={colors.Декрет} />
                                ))}
                            </Bar>

                            <Bar
                                dataKey="svo"
                                name="СВО"
                                fill={colors.СВО}
                                label={{ position: "top" }}
                            >
                                {data?.map((entry, index) => (
                                    <Cell key={`cell-svo-${index}`} fill={colors.СВО} />
                                ))}
                            </Bar>

                            <Bar
                                dataKey="allnumshift"
                                name="Всего"
                                fill={colors.Всего}
                                label={{ position: "top" }}
                            >
                                {data?.map((entry, index) => (
                                    <Cell key={`cell-svo-${index}`} fill={colors.Всего} />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>

                <div className="block_employee_barchat_shift" style={{ width: "100%", height: "400px" }}>
                    <h3>Распределение возрастов сотрудников по датам</h3>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                        data={transformedData}
                        margin={{ top: 20, right: 30, bottom: 5, left: 20 }}
                        >
                        <XAxis dataKey="category" tick={{ fontSize: 12 }} />
                        <YAxis />
                        <Tooltip
                            formatter={(value, name) => [`${value} чел.`, name]}
                            labelFormatter={(label, payload) => {
                            if (payload && payload.length > 0) {
                                const date = payload[0].payload.datesave;
                                return `Дата: ${date} | ${label}`;
                            }
                            return `Категория: ${label}`;
                            }}
                        />
                        <Legend />
                        <Bar dataKey="Мужчины" fill="#8884D8" />
                        <Bar dataKey="Женщины" fill="#F387FF" />
                        </BarChart>
                    </ResponsiveContainer>

                    <div style={{ textAlign: "center", marginTop: "10px", display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-around" }}>
                        {data?.map((entry, index) => (
                        <div key={index} style={{ display: "inline-block", margin: "0 15px" }}>
                            <strong>{entry.datesave}</strong>
                        </div>
                        ))}
                    </div>
                </div>

                <div className="block_employee_barchat_shift" style={{width: "100%", marginLeft: "0px", fontWeight: "400"}}>
                    <div style={{fontWeight: "700", fontSize: "18px"}} >Стаж</div>
                    <ResponsiveContainer width="90%" height={300}>
                        <BarChart
                            data={data}
                            margin={{ top: 20, right: 30, bottom: 5, left: 20 }}
                        >
                            <XAxis dataKey="datesave" />
                            <YAxis />
                            <Tooltip />
                            <Legend />

                            <Bar
                                dataKey="staz1"
                                name="Меньше месяца"
                                fill="#6C58F1"
                                label={{ position: "top" }}
                            >
                                {data?.map((entry, index) => (
                                    <Cell key={`cell-red-${index}`} fill="#6C58F1" />
                                ))}
                            </Bar>

                            <Bar
                                dataKey="staz2"
                                name="От 1 до 6 месяцев"
                                fill="#5948C6"
                                label={{ position: "top" }}
                            >
                                {data?.map((entry, index) => (
                                    <Cell key={`cell-orange-${index}`} fill="#5948C6" />
                                ))}
                            </Bar>

                            <Bar
                                dataKey="staz3"
                                name="От 6 до 12 месяцев"
                                fill="#46399C"
                                label={{ position: "top" }}
                            >
                                {data?.map((entry, index) => (
                                    <Cell key={`cell-yellow-${index}`} fill="#46399C" />
                                ))}
                            </Bar>

                            <Bar
                                dataKey="staz4"
                                name="От года до 2х лет"
                                fill="#332971"
                                label={{ position: "top" }}
                            >
                                {data?.map((entry, index) => (
                                    <Cell key={`cell-green-${index}`} fill="#332971" />
                                ))}
                            </Bar>

                            <Bar
                                dataKey="staz5"
                                name="От 2х до 3х лет"
                                fill="#201A47"
                                label={{ position: "top" }}
                            >
                                {data?.map((entry, index) => (
                                    <Cell key={`cell-podrab-${index}`} fill="#201A47" />
                                ))}
                            </Bar>

                            <Bar
                                dataKey="staz6"
                                name="Больше 3х лет"
                                fill="#0D0A1C"
                                label={{ position: "top" }}
                            >
                                {data?.map((entry, index) => (
                                    <Cell key={`cell-projectchz-${index}`} fill="#0D0A1C" />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>

            </div>

        </div>

        {notification.isVisible && (
            <Notification
            status={notification.status}
            title={notification.title}
            subtitle={notification.subtitle}
            onClose={hideNotification}
            isVisible={notification.isVisible}
            subtitle2={notification.subtitle2}
            />
        )}
      </div>
    );
  };
  
  export default StatsSave;