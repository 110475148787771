import React, { useEffect, useState } from "react";
import '../Styles/homepage.scss';
import {
  fetchEmployee,
  fetchFiredEmployee
} from "../api_control.ts";
import {
  fetchEmployeesWithLMKExpired,
  fetchEmployeesWithSIZExpired,
  fetchEmployeesWithADSMExpired,
  fetchEmployeesWithFLGExpired,
  fetchEmployeesWithOnJobExpired,
  fetchEmployeesWithFireExpired,
  fetchEmployeesWithElectroExpired,
  fetchNumShift,
  fetchStats
} from "../api_control.ts";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Cell } from "recharts";
import { useNavigate } from "react-router-dom";

interface StatsData {
  shift: string;
  employees: number;
}

const Homepage = () => {
  const role = localStorage.getItem("role");
  const fio = localStorage.getItem("fio");

  const [lenEmpl, setLenEmpl] = useState("");
  const [lenFiredEmpl, setLenFiredEmpl] = useState("");
  const [instLen, setInstLen] = useState(0);
  const [numShift, setNumShift] = useState([]);
  const [numStats, setNumStats] = useState<StatsData[]>([]);

  const orderedCategories = [
    "Меньше месяца",
    "От 1 до 6 месяцев",
    "От 6 до 12 месяцев",
    "От года до 2х лет",
    "От 2х до 3х лет",
    "Больше 3х лет"
  ];

  const today = new Date();

  const formattedDate = new Intl.DateTimeFormat("ru-RU", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  }).format(today);

  const date_today =
    formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

  const fetchData = async () => {
    try {
      const dataEmpl = await fetchEmployee();
      setLenEmpl(dataEmpl ? dataEmpl.length : 0);
      const dataFiredEmpl = await fetchFiredEmployee();
      setLenFiredEmpl(dataFiredEmpl ? dataFiredEmpl.length : 0);

      const dataNumShift = await fetchNumShift();
      setNumShift(dataNumShift);

      const numSt = await fetchStats();
      const sortedResult = numSt.sort((a, b) => {
        return orderedCategories.indexOf(a.shift) - orderedCategories.indexOf(b.shift);
      });
      setNumStats(sortedResult);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData2 = async () => {
      try {
        // //ЛМК
        const data2 = await fetchEmployeesWithLMKExpired();

        // //СИЗ
        const data4 = await fetchEmployeesWithSIZExpired();

        // //ФЛГ
        const data6 = await fetchEmployeesWithFLGExpired();

        // //АДСМ
        const data8 = await fetchEmployeesWithADSMExpired();

        //Инструктажи
        const data10 = await fetchEmployeesWithOnJobExpired();
        const data12 = await fetchEmployeesWithFireExpired();
        const data14 = await fetchEmployeesWithElectroExpired();

        setInstLen(
          data2.length +
            data4.length +
            data6.length +
            data8.length +
            data10.length +
            data12.length +
            data14.length
        );
      } catch (error) {
        console.error(error);
      }
    };

    fetchData2();
  }, []);


  const colors = [
    "#d60000",
    "#ff8c00",
    "#f9d400",
    "#00840d",
    "#320e54",
    "#000000",
    "#e2e8f1",
    "#e2e8f1",
    "#e2e8f1",
    "#e2e8f1",
    "#8884D8",
  ];

  const orderedShifts = [
    "Меньше месяца",
    "От 1 до 6 месяцев",
    "От 6 до 12 месяцев",
    "Больше года",
  ];

  const navigate = useNavigate();

  const nextControl = () => {
    navigate("/control_briefings");
  };

  const nextEmployee = () => {
    navigate("/employee");
  };

  return (
    <div className="homepage_container">
      <div className="homepage_header">
        <div className="header_title_info">
          {role === "manager" && <div>менеджер</div>}
          {role === "brig" && <div>бригадир</div>}
          <div>Бураков Андрей Михайлович</div>
        </div>
        <img
          src="https://workspace.ru/upload/main/e35/9drglma6601fwku6py9zfurze84xtx7t/reviewsphoto.png"
          width="52px"
          height="52px"
          alt="avatar"
          style={{ marginRight: "15px", marginLeft: "auto" }}
        />
      </div>
      <div className="homepage_dashboard_container">
        <div
          className="block_employee_num"
          style={{ marginLeft: "15px", width: "380px" }}
        >
          <div className="circle_dashboard">
            <img
              src={require("../Assets/calendar.png")}
              alt=""
              width="35px"
              height="35px"
            />
          </div>
          <div className="info_employee_num">
            <div>Сегодня</div>
            <div>{date_today}</div>
          </div>
        </div>

        <div
          className="block_employee_num nextControl"
          onClick={nextControl}
          style={{ marginLeft: "15px", width: "220px" }}
        >
          <div className="circle_dashboard">
            <img
              src={require("../Assets/fire.png")}
              alt=""
              width="35px"
              height="35px"
            />
          </div>
          <div className="info_employee_num">
            <div>Контроль сроков</div>
            <div>{instLen}</div>
          </div>
        </div>

        <div className="block_employee_num nextControl"
          onClick={nextEmployee}
        >
          <div className="circle_dashboard">
            <img
              src={require("../Assets/employee.png")}
              alt=""
              width="35px"
              height="30px"
            />
          </div>
          <div className="info_employee_num">
            <div>Активные сотрудники</div>
            <div>{lenEmpl}</div>
          </div>
        </div>

        {/* <div
          className="block_employee_num"
          style={{ marginLeft: "15px", width: "265px" }}
        >
          <div className="circle_dashboard">
            <img
              src={require("../Assets/employee_fired.png")}
              alt=""
              width="35px"
              height="35px"
            />
          </div>
          <div className="info_employee_num">
            <div>Уволенные сотрудники</div>
            <div>{lenFiredEmpl}</div>
          </div>
        </div> */}

        {numShift && (
          <div className="block_employee_barchat_shift">
            <div style={{fontWeight: "700", fontSize: "18px"}} >Наполняемость смен</div>
            <BarChart
              width={500}
              height={300}
              data={numShift}
              margin={{ top: 20, right: 30, bottom: 5, left: 20 }}
            >
              <XAxis dataKey="shift" />
              <YAxis />
              <Tooltip />
              {numShift && (
                <Bar
                  dataKey="employees"
                  fill="#8884d8"
                  label={{ position: "top" }}
                >
                  {numShift.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                  ))}
                </Bar>
              )}
            </BarChart>
          </div>
        )}

        {numStats && (
          <div className="block_employee_barchat_shift">
            <div style={{fontWeight: "700", fontSize: "18px"}}>Стаж</div>
            <BarChart
              width={500}
              height={300}
              data={numStats}
              margin={{ top: 20, right: 30, bottom: 5, left: 20 }}
            >
              <XAxis dataKey="shift" />
              <YAxis />
              <Tooltip />
              <Bar
                dataKey="employees"
                fill="#8884d8"
                label={{ position: "top" }}
              ></Bar>
            </BarChart>
          </div>
        )}
      </div>
    </div>
  );
}
 
export default Homepage;